<template>
  <div>
    <section>
      <b-button
        variant="outline-primary"
        class="btn my-25 my-md-0 mr-md-1"
        @click="switchTab(0)"
        :class="{ 'bg-primary text-light': activeTab == 0 }"
        >مستخدمين</b-button
      >

      <b-button
        @click="switchTab(1)"
        variant="outline-primary"
        class="btn my-25 my-md-0 mr-md-1"
        :class="{ 'bg-primary text-light': activeTab == 1 }"
        >فعاليات</b-button
      >
    </section>
    <section class="sub-filtering-area mb-2">
      <b-row align-v="center">
        <b-col lg="4">
          <div class="sub-filtering-area__filter-title mb-1">فلتر حسب:</div>
        </b-col>

        <b-col
          lg="4"
          md="6"
          xs="6"
          class="sub-filtering-area__filter-by-activity-container d-flex w-100"
        >
          <div class="sub-filtering-area__activity-filter-title mr-1">حالة الإشتراك:</div>
          <ek-input-select
            clearable
            :options="subStatusList"
            @input="setStatusFilter"
            name="filterendSub"
            class="sub-filtering-area__activity-filter-input"
          />
        </b-col>

        <b-col
          lg="4"
          md="6"
          xs="6"
          class="sub-filtering-area__filter-by-ads-status-container d-flex"
        >
          <div class="sub-filtering-area__status-filter-title mr-1">نهاية الإشتراك :</div>
          <ek-date-picker
            range
            name="statusFtiler"
            class="sub-filtering-area__status-filter-input"
            @input="setSubEndFilter"
          />
        </b-col>
      </b-row>
    </section>
    <div>
      <ek-table
        :columns="cols"
        :items="activeSubscriptionsList"
        deleteBtn
        @delete-selected="deleteSubs"
        @details="goToDetails"
      >
        <template slot="items.startSub" slot-scope="{ props }">
          <span class="card-date" dir="ltr">{{props.row.startSub ?  formatDate(props.row.startSub) : '-' }}</span>
        </template>

        <template slot="items.endSub" slot-scope="{ props }">
          <span class="card-date" dir="ltr">{{ props.row.endSub ?  formatDate(props.row.endSub) : '-' }}</span>
        </template>

        <template slot="items.subStatuses" slot-scope="{ value }">
          <StatusBadge :statusList="subStatus" :selectedStatusNumber="value" />
        </template>
      </ek-table>
    </div>
    <ek-modal
      @delete="
        deleteSub(subDetailsDto.id);
        $refs.detailsModal.close();
      "
      size="lg"
      ref="detailsModal"
      editMode
      title="تفاصيل اشتراك"
      @edit="edit"
    >
      <ValidationObserver ref="formObserver">
          <div class="d-flex align-items-center mb-1">
                <p class="mb-0 mr-1">حالة الإشتراك:</p>
                <StatusBadge :statusList="subStatus" :selectedStatusNumber="subDetailsDto.subStatuses" />
          </div>
        <b-row>
          <b-col cols="6">
            <ek-input-text
              readonly
              name="اسم المستخدم"
              label="اسم المستخدم"
              v-model="subDetailsDto.accountName"
            ></ek-input-text>
          </b-col>
          <b-col cols="6">
            <ek-input-text
              readonly
              v-model="subDetailsDto.subNumber"
              name="رقم الإشتراك"
              label="رقم الإشتراك"
            ></ek-input-text>
          </b-col>
          <b-col>
            <ek-date-picker
              :rules="[
                {
                  type: 'required',
                  message: 'مدة الاشتراك مطلوبة',
                },
              ]"
              label="بداية الإشتراك"
              v-model="subDetailsDto.startSub"
              @onChange="getFtrs"
              name="subDate"
            />
          </b-col>
          <b-col>
            <ek-date-picker
              :rules="[
                {
                  type: 'required',
                  message: 'مدة الاشتراك مطلوبة',
                },
              ]"
              label="نهاية الإشتراك"
              @onChange="getFtrs"
              v-model="subDetailsDto.endSub"
              name="subDate"
            />
          </b-col>
          <b-col cols="12">
            <ek-input-select
              :rules="[
                {
                  type: 'required',
                  message: 'الميزات مطلوبة',
                },
              ]"
              multiple
              :options="filterdFeatures"
              v-model="subDetailsDto.features"
              name="الميزات"
              placeholder="اختر ميزات المستخدم"
              label="ميزات المستخدم"
            ></ek-input-select>
          </b-col>
        </b-row>
      </ValidationObserver>
    </ek-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import StatusBadge from "@/app/global/components/StatusBadge";
export default {
  components: {
    StatusBadge,
  },
  data: () => ({
    cols: [
      {
        label: "الاسم ",
        field: "accountName",
      },
      {
        label: "البريد الالكتروني",
        field: "userName",
      },
      {
        label: "رقم الإشتراك",
        field: "subNumber",
      },
      {
        label: "بداية الإشتراك",
        field: "startSub",
      },
      {
        label: "نهاية الإشتراك",
        field: "endSub",
      },
      {
        label: "حالة الإشتراك",
        field: "subStatuses",
      },
      {
        label: "تفاصيل ",
        field: "details",
      },
    ],
    subStatusList: [
      { id: 1, name: "فعال" },
      { id: 2, name: "غير فعال" },
    ],
    filter: {
      subStatus: "",
      subEnd: "",
    },
  }),
  computed: {
    ...mapState({
      filterdFeatures: ({ subscriptions }) => subscriptions.filterdFeatures,
      subDetailsDto: ({ subscriptions }) => subscriptions.subDetailsDto,
      startSub: ({ subscriptions }) => subscriptions.subDetailsDto.startSub,
      endSub: ({ subscriptions }) => subscriptions.subDetailsDto.endSub,
      subStatus: (state) => state.subscriptions.subStatus,
      activeTab: (state) => state.subscriptions.activeTab,
    }),
    ...mapGetters(["AppUserList", "SubActivityList", "activeSubscriptionsList"]),
  },

  created() {
    this.getAllAppUser();
    this.getAllActivity();
  },
  methods: {
    ...mapActions([
      "getAllActivity",
      "getAllAppUser",
      "deleteSubs",
      "deleteSub",
      "getFeatures",
      "updateActivitySubscripe",
      "updateUserSubscripe",
    ]),
    ...mapMutations(["Set_Subscripe_Filter"]),
    switchTab(payload) {
      this.$store.commit("Set_Active_Tab", payload);
    },
    formatDate(date) {
      let newDate = `${new Date(date).getFullYear()}-${
        new Date(date).getMonth() + 1
      }-${new Date(date).getDate()}`;
      newDate += `  `;

      return newDate;
    },
    getList() {
      if (this.activeTab == 0) return this.AppUserList;
      else return this.SubActivityList;
    },
    goToDetails({ row }) {
      this.$store.commit("Get_Details_Dto", row);
      this.$refs.detailsModal.open();
    },
    setStatusFilter(e) {
      this.filter.subStatus = e;
      this.Set_Subscripe_Filter(this.filter);
    },
    setSubEndFilter(e) {
      if (e.length > 10) {
        this.filter.fromDate = new Date(e.split("to")[0].trim());
        this.filter.toDate = new Date(e.split("to")[1].trim());
        this.Set_Subscripe_Filter(this.filter);
      } else {
        this.filter.fromDate = "";
        this.filter.toDate = "";
        this.Set_Subscripe_Filter(this.filter);
      }
    },
    getFtrs() {
      this.getFeatures({
        accountId: this.subDetailsDto.accountId,
        featuresType: this.activeTab == 0 ? 2 : 4,
        startSub: this.subDetailsDto.startSub,
        endSub: this.subDetailsDto.endSub,
      });
    },
    edit() {
        this.$refs.formObserver.validate().then((success) => {
        if (success) {
            this.activeTab == 0
                ? this.updateUserSubscripe(this.subDetailsDto)
                : this.updateActivitySubscripe(this.subDetailsDto);
        }
      });
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.sub-filtering-area {
  align-items: center;

  @media only screen and (max-width: 780px) {
    flex-direction: column;
  }

  &__filter-title {
    font-weight: bold;
    font-size: 1rem;

    color: #6e6b7b;
  }

  &__filter-container {
    display: flex;
    gap: 2rem;
  }

  &__filter-by-activity-container {
    display: flex;
  }

  &__activity-filter-title {
    margin-top: 1.3rem;
    font-size: 1.1rem;

    color: #6e6b7b;
  }

  &__activity-filter-input {
    flex: 1;
  }

  &__filter-by-ads-status-container {
    display: flex;
  }

  &__status-filter-title {
    margin-top: 1.4rem;

    font-size: 1.1rem;

    color: #6e6b7b;

    @media only screen and (max-width: 780px) {
      margin-left: 0px;
    }
  }

  &__status-filter-input {
    flex: 1;
  }
  .card-date {
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 21px;
    color: #000000;
  }
}
</style>
